export const SET_SUBSCRIPTIONS_LIST = "SET_SUBSCRIPTIONS_LIST";
export const SET_VIEW_ORDER_SUMMARY = "SET_VIEW_ORDER_SUMMARY";
export const SET_SUBSCRIPTIONS_PAGE = "SET_SUBSCRIPTIONS_PAGE";
export const SET_SUBSCRIPTION_FILTER = "SET_SUBSCRIPTION_FILTER";
export const SET_SUBSCRIPTIONS_LOADING = "SET_SUBSCRIPTIONS_LOADING";
export const CLEAR_SUBSCRIPTIONS_STORE = "CLEAR_SUBSCRIPTIONS_STORE";
export const SET_VIEW_SUBSCRIPTION = "SET_VIEW_SUBSCRIPTION";
export const SET_DELIVERY_SHIFTS = "SET_DELIVERY_SHIFTS";
export const SET_VIEW_SUBSCRIPTION_LOADING = "SET_VIEW_SUBSCRIPTION_LOADING";
export const SET_MEAL_LOADER = "SET_MEAL_LOADER";
export const SET_CREATE_PLANNED_ORDER_LOADING =
  "SET_CREATE_PLANNED_ORDER_LOADING";
export const SET_PLANNED_ITEM_LOADING = "SET_PLANNED_ITEM_LOADING";
export const SET_PLANNED_DATE = "SET_PLANNED_DATE";
export const SET_PAUSE_DAYS = "SET_PAUSE_DAYS";
export const SET_FREEZE_DAYS = "SET_FREEZE_DAYS";
export const SET_SUBS_DIALOG_FULLWIDTH = "SET_SUBS_DIALOG_FULLWIDTH";
export const SET_REQUEST_LIST = "SET_REQUEST_LIST";
export const SET_REQUEST_PAGE = "SET_REQUEST_PAGE";
export const SET_REQUEST_FILTER = "SET_REQUEST_FILTER";
export const SET_REQUEST_OBJ = "SET_REQUEST_OBJ";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
export const SET_PORTION_LIST = "SET_PORTION_LIST";
export const UPDATE_VIEW_SUBSCRIPTION_MEAL = "UPDATE_VIEW_SUBSCRIPTION_MEAL";
export const SET_SELECTED_MEAL_DATE_FOR_SUBSCRIPTION_VIEW =
  "SET_SELECTED_MEAL_DATE_FOR_SUBSCRIPTION_VIEW";
export const SET_ADD_SUBSCRIPTION_DAYS_LOADING =
  "SET_ADD_SUBSCRIPTION_DAYS_LOADING";
export const SET_REMOVE_SUBSCRIPTION_DAYS_LOADING =
  "SET_REMOVE_SUBSCRIPTION_DAYS_LOADING";
export const SET_UPDATE_SUBSCRIPTION_START_DATE_LOADING =
  "SET_UPDATE_SUBSCRIPTION_START_DATE_LOADING";

export const UPDATE_ADDRESS_AND_SHIFT_IN_VIEW_SUBSCRIPTION =
  "UPDATE_ADDRESS_AND_SHIFT_IN_VIEW_SUBSCRIPTION";

  export const CANCEL_SUBSCRIPTION_LOADING =
  "CANCEL_SUBSCRIPTION_LOADING";

  export const REFUND_REQUEST_LOADING =
  "REFUND_REQUEST_LOADING";
export const UPDATE_START_END_DATE_IN_SUBSCRIPTIONS =
  "UPDATE_START_END_DATE_IN_SUBSCRIPTIONS";
  
  export const UPDATE_STATUS_SUBSCRIPTIONS =
  "UPDATE_STATUS_SUBSCRIPTIONS";
