import React, { useRef } from "react";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { login } from "../../../redux/actions/auth";
import { useDispatch } from "react-redux";
import { alertMessage } from "app/redux/actions/common";
import { AuthParent, AuthButton } from "../common";
const validationSchema = yup.object({
  username: yup.string("Enter your Username").required("Username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = () => {
  const { setAuthToken } = useJumboAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(undefined);

  const onSignIn = (payload) => {
    const body = {
      ...payload,
      authMethod: "usernamePassword",
    };
    dispatch(login(body, loginCallBack));
  };

  const loginCallBack = (response, error) => {
    formRef.current?.setSubmitting(false);
    if (error) {
      dispatch(alertMessage(error?.error?.message, "error"));
      return;
    }
    setAuthToken(response?.accessToken);
    navigate("/dashboards/menus/catalogs");
  };
  return (
    <AuthParent
      heading="Sign In"
      form={
        <Formik
          validateOnChange={true}
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSignIn(data);
          }}
          innerRef={formRef}
        >
          {({ isSubmitting }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Div sx={{ mt: 1, mb: 3 }}>
                <JumboTextField fullWidth name="username" label="Username" />
              </Div>
              <Div sx={{ mt: 1, mb: 2 }}>
                <JumboTextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                />
              </Div>
              <AuthButton loading={isSubmitting} label="Sign In" />
            </Form>
          )}
        </Formik>
      }
      links={[
        {
          label: "Forgot your password? Recover Now test",
          to: "/auth/forgot-password",
        },
        // {
        //   label: "No access yet? Ask for access",
        //   to: "/auth/ask-for-access",
        // },
      ]}
    />
  );
};

export default Login;
