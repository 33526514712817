import axios from "./config";
import { getToken } from "app/services/authService";
import { getActiveConfig } from "app/utils/constants/common";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { REACT_APP_BASE_URL, REACT_APP_GOOGLE_KEY } = process.env;

export const getCountriesAPI = () =>
  axios.get("/Countries", {
    headers: {
      "accept-language": "",
    },
  });

export const getPackagesAPI = (query: any) => {
  return axios.get(`/Packages${query}`);
};

export const getBrandsAPI = () => axios.get("/brands");
export const getStoresAPI = () => axios.get("/stores");

export const getBundlesAPI = (query: any) => axios.get(`/bundles${query}`);

export const getGovenratesAndDistrictsApi = () => {
  // NOTE: for now this is only for kw/ once other locale is enable we need to adjust this.
  const { activeLocale } = getActiveConfig();
  return axios.get(
    `/provinces/${activeLocale.defaultProvinceId}/all-districts`
  );
};

export const getPatmentProvidersAPI = (storeId: any) => {
  return axios.get(`/Stores/${storeId}/payment-providers`, {
    headers: {
      Application: "dashboard",
    },
  });
};

export const getAllPreferencesAPI = () => axios.get("/items");

export const getConfigurationsAPI = (payload: any) => {
  const { locale, application } = payload;
  return axios.get(`/configurations?locale=${locale}`, {
    headers: {
      Application: application,
    },
  });
};

export const getAllergiesAPI = () => axios.get(`/allergic-Items`);

// get governorate, area, block from google api
export const getGoogleMapDataAPI = (lat: any, lng: any) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=neighborhood&key=${REACT_APP_GOOGLE_KEY}&language=en`
  );
};
export const downloadLinkAPI = (uri: any, fileName: any) => {
  const url = `${REACT_APP_BASE_URL}${uri}`;
  const headers = new Headers();
  const token = getToken();
  headers.append("Authorization", `Bearer ${token}`);

  fetch(url, {
    method: "GET",
    headers,
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => console.error(error));
};

export const downloadAndPreview = (uri: any) => {
  const url = `${REACT_APP_BASE_URL}${uri}`;
  const headers = new Headers();
  const token = getToken();
  headers.append("Authorization", `Bearer ${token}`);

  fetch(url, {
    method: "GET",
    headers,
  })
    .then((response) => {
      const contentType = response.headers.get("content-type");
      return response.blob().then((blob) => ({ blob, contentType }));
    })
    .then(({ blob, contentType }) => {
      // Check if the response is a PDF
      if (contentType === "application/pdf") {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, "_blank"); // Open in a new tab
      }
    })
    .catch((error) => console.error(error));
};
export const downloadLinkAPIV2 = (uri: any) => {
  const url = `${REACT_APP_BASE_URL}${uri}`;
  const headers = new Headers();
  const token = getToken();
  headers.append("Authorization", `Bearer ${token}`);

  fetch(url, {
    method: "GET",
    headers,
  })
    .then((response) => response.json())
    .then((data) => {
      const downloadURL = data.url;
      const downloadWindow = window.open(downloadURL);
      setTimeout(() => {
        downloadWindow?.close();
      }, 5000); // Close the window after 5 seconds, adjust if needed
    })
    .catch((error) => console.error(error));
};

export const printDownloadLinkAPI = (uri: any, printer: any) => {
  const url = `${REACT_APP_BASE_URL}${uri}`;
  const headers = new Headers();
  const token = getToken();
  headers.append("Authorization", `Bearer ${token}`);

  fetch(url, {
    method: "GET",
    headers,
  })
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => {
      // Convert the array buffer to a PDF document
      return pdfjs.getDocument(arrayBuffer).promise;
    })
    .then((pdf) => {
      // Render the first page of the PDF on a canvas
      return pdf.getPage(1).then((page) => {
        const scale = 2.5;
        const viewport = page.getViewport({ scale });

        const offScreenCanvas = document.createElement("canvas");
        const offScreenContext = offScreenCanvas.getContext("2d");
        offScreenCanvas.width = viewport.width;
        offScreenCanvas.height = viewport.height;

        const renderContext = {
          canvasContext: offScreenContext,
          viewport: viewport,
        };

        return page.render(renderContext).promise.then(() => {
          // Convert the off-screen canvas to data URL
          return offScreenCanvas.toDataURL("image/png");
        });
      });
    })
    .then((dataUrl) => {
      // Create an image element to hold the PNG data
      const img = new Image();
      img.src = dataUrl;
      img.onload = function () {
        // Call the function to print the PNG image
        if (printer) {
          printer.brightness = 1.0;
          printer.halftone = printer.HALFTONE_THRESHOLD;
          const offScreenCanvas: HTMLCanvasElement =
            document.createElement("canvas");
          offScreenCanvas.id = "canvas";
          document.body.appendChild(offScreenCanvas);
          const ctx: CanvasRenderingContext2D | null =
            offScreenCanvas.getContext("2d");
          if (ctx) {
            offScreenCanvas.width = img.width;
            offScreenCanvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            printer.addImage(
              ctx,
              0,
              0,
              offScreenCanvas.width,
              offScreenCanvas.height,
              printer.COLOR_1,
              printer.MODE_MONO
            );
            printer.addCut(printer.CUT_FEED);
            printer.send();

            // Remove the canvas element once printing is complete
            document.body.removeChild(offScreenCanvas);
          }
        }
      };
    });
};


