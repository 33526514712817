import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { deleteStoreConfigurationAPI, getCustomerIssuesAPI, getStoreConfigurationAPI, postStoreConfigurationAPI } from "app/services/customerSupport";
export interface IssuesList {
  id: number;
  subject: string;
  description: string;
  createdAt: string;
  status: string;
  url: string;
}
export interface configListPayload{
  startTime: string,
  endTime: string,
  supportDays: string[],
  supportChannel: string,
}
export interface ConfigurationsList  {
  id: number,
  storeId: number,
  supportDays:string [],
  supportChannel: string, 
  startTime: string,
  endTime: string,
  isActive: boolean,
  userId: number,
  createdAt: string
}
export interface SupportConfigFilters {
  brandId: number;
}
interface InitialState {
  customerIssues: { list: IssuesList[] };
  loading:boolean;
  configurationsList:{ list: ConfigurationsList[] };
  filters:SupportConfigFilters|{};
}

const initialState: InitialState = {
  customerIssues: { list: [] },
  loading:false,
  configurationsList:{ list: [] },
  filters: { brandId: 2 },
};

export const customerSupportSlice = createSlice({
  name: "customerSupport",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    setCustomerIssuesList: (
      state,
      action: PayloadAction<{
        list: IssuesList[];
      }>
    ) => {
      state.customerIssues = {
        list: action.payload.list,
      };
    },
    setSupportConfigFilters: (
      state,
      action: PayloadAction<SupportConfigFilters | {}>
    ) => {
      state.filters = action.payload;
    },
    setConfigurationsList: (
      state,
      action: PayloadAction<{
        list: ConfigurationsList[];
      }>
    ) => {
      state.configurationsList = {
        list: action.payload.list,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setCustomerIssuesList,setConfigurationsList,setSupportConfigFilters } =
  customerSupportSlice.actions;

export default customerSupportSlice.reducer;

export const getCustomerIssues = (
  clientId: number,
  cb?: Function,
  errorCb?: Function
) => {
  return async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));
      const { data } = await getCustomerIssuesAPI(clientId);
      dispatch(setLoading(false));
      dispatch(
        setCustomerIssuesList({
          list: data.data,
        })
      );
    
      cb?.(data?.data);
    } catch (err) {
      errorCb?.();
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};
export const getConfigurationsList = (
  storeId: number,
  cb?: Function,
  errorCb?: Function
) => {
  return async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));
      const { data } = await getStoreConfigurationAPI(storeId);
      dispatch(setLoading(false));
      dispatch(
        setConfigurationsList({
          list: data.data,
        })
      );
    
      cb?.(data?.data);
    } catch (err) {
      errorCb?.();
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};
export const createConfigurationAction = ( storeId: number,payload:configListPayload,
 ) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await postStoreConfigurationAPI(storeId,payload);
      dispatch(alertMessage("Added successfully", "success"));
      return data; 
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      throw error;
    }
  };
};
export const deleteConfiguration = (
  storeId: number,
  id: number,
  cb?: Function,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await deleteStoreConfigurationAPI(storeId, id);
      dispatch(alertMessage("Deleted successfully", "success"));
      cb?.();
    } catch (err) {
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};