import { AxiosResponse } from "axios";
import axios from "./config";
import { createQuery } from "app/utils/constants/common";
import { ClientIdentityApiResponse } from "app/types/clients";

export const insertClientAPI = (data: any) => axios.post("/clients", data);

export const getClientsAPI = (query: string) => axios.get(`/clients/${query}`);

export const getClientsWaitingListAPI = (storeId: number, payload: string) =>
  axios.get(`/stores/${storeId}/waiting-list-clients${payload}`);

export const getstoreByIdAPI = (storeId: number) =>
  axios.get(`/stores/${storeId}`);

export const getClientAPI = (id: any) => axios.get(`/clients/${id}`);

export const putClientWaitingListStatus = (
  id: number,
  store: number,
  payload: {}
) => axios.put(`/stores/${store}/waiting-list-clients/${id}`, payload);

export const updateClientAPI = (id: any, data: any) =>
  axios.put(`/clients/${id}`, data);

export const deleteClientAPI = (id: any) => axios.delete(`/clients/${id}`);

export const getClientDeliveryAddressAPI = (clientId: any) =>
  axios.get(`/clients/${clientId}/addresses`);

export const createNewAddress = (id: any, data: any) =>
  axios.post(`/clients/${id}/addresses`, data);

export const updateAddressAPI = (clientId: any, addressId: any, data: any) =>
  axios.put(`/clients/${clientId}/addresses/${addressId}`, data);

export const getAddressByIdAPI = (clientId: any, addressId: any) =>
  axios.get(`/clients/${clientId}/addresses/${addressId}`);

export const getPreferencesByClientAPI = (clientId: any, query: any) =>
  axios.get(`/clients/${clientId}/meal-preferences${query}`);

export const insertPreferencesByClientAPI = (clientId: any, data: any) =>
  axios.post(`/clients/${clientId}/meal-preferences`, data);

export const deletePreferencesByClientAPI = (clientId: any, id: any) =>
  axios.delete(`/clients/${clientId}/meal-preferences/${id}`);

export const getClientVisitsAPI = (clientId: any, payload: any) => {
  let query = "";

  if (payload) {
    query = "?" + createQuery({ filter: payload });
  }

  return axios.get(`/clients/${clientId}/visits${query}`);
};

export const getAllVisitsAPI = (query: any) => {
  return axios.get(`/visits${query}`);
};

export const getScheduleVisitAPI = (query: any) => {
  return axios.get(`/scheduled-visits${query}`);
};

export const createClientVisitAPI = (clientId: any, payload: any) => {
  return axios.post(`/clients/${clientId}/visits`, payload);
};

export const createSchedVisitAPI = (clientId: any, payload: any) => {
  return axios.post(`/clients/${clientId}/scheduled-visits`, payload);
};

export const getVisitAPI = (clientId: any, visitId: any) => {
  return axios.get(`/clients/${clientId}/visits/${visitId}`);
};

export const putVisitAPI = (clientId: any, payload: any, visitId: any) => {
  return axios.put(`/clients/${clientId}/visits/${visitId}`, payload);
};

export const putSchedVisitAPI = (clientId: any, payload: any, visitId: any) => {
  return axios.put(`/clients/${clientId}/scheduled-visits/${visitId}`, payload);
};

export const getClientAllergiesAPI = (clientId: any) =>
  axios.get(`clients/${clientId}/allergy-preferences`);

export const addClientAllergyAPI = (clientId: any, payload: any) =>
  axios.post(`clients/${clientId}/allergy-preferences`, payload);

export const deleteClientAllergyAPI = (clientId: any, allergyId: any) =>
  axios.delete(`clients/${clientId}/allergy-preferences/${allergyId}`);

export const multideleteAllergyAPI = (clientId: any, payload: any) =>
  axios.post(`clients/${clientId}/allergy-preferences`, payload);

export const getDieticianAPI = () => {
  return axios.get(`/dietitans`);
};

export const getClientIdentityApi = (
  clientId: number
): Promise<AxiosResponse<ClientIdentityApiResponse>> =>
  axios.get<ClientIdentityApiResponse>(`/clients/${clientId}/identity`);

export const updateClientIdentityStatusApi = (payload: {
  identityId: number;
  type: string;
  enable: boolean;
  reason: string;
}) => axios.post(`/identities/${payload.identityId}/status`, payload);
