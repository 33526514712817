import produce from "immer";
import {
  SET_SUBSCRIPTIONS_LIST,
  SET_SUBSCRIPTIONS_PAGE,
  SET_SUBSCRIPTION_FILTER,
  SET_SUBSCRIPTIONS_LOADING,
  CLEAR_SUBSCRIPTIONS_STORE,
  SET_VIEW_SUBSCRIPTION,
  SET_VIEW_SUBSCRIPTION_LOADING,
  SET_DELIVERY_SHIFTS,
  SET_MEAL_LOADER,
  SET_CREATE_PLANNED_ORDER_LOADING,
  SET_PLANNED_ITEM_LOADING,
  SET_PLANNED_DATE,
  SET_PAUSE_DAYS,
  SET_SUBS_DIALOG_FULLWIDTH,
  SET_REQUEST_LIST,
  SET_REQUEST_PAGE,
  SET_REQUEST_FILTER,
  SET_REQUEST_OBJ,
  SET_ORDER_DETAIL,
  SET_PORTION_LIST,
  SET_VIEW_ORDER_SUMMARY,
  UPDATE_VIEW_SUBSCRIPTION_MEAL,
  SET_SELECTED_MEAL_DATE_FOR_SUBSCRIPTION_VIEW,
  SET_ADD_SUBSCRIPTION_DAYS_LOADING,
  SET_REMOVE_SUBSCRIPTION_DAYS_LOADING,
  REFUND_REQUEST_LOADING,
  CANCEL_SUBSCRIPTION_LOADING,
  SET_UPDATE_SUBSCRIPTION_START_DATE_LOADING,
  UPDATE_ADDRESS_AND_SHIFT_IN_VIEW_SUBSCRIPTION,
  UPDATE_START_END_DATE_IN_SUBSCRIPTIONS,
  UPDATE_STATUS_SUBSCRIPTIONS,
} from "app/utils/constants/subscriptions";
import { PortionItem } from "app/types";
import {
  DeliveryShift,
  ViewSubscriptionInterface,
} from "app/types/subscriptionsManager";

interface InitialState {
  subscriptions_list: any;
  express_Order: any;
  viewExpressOrderSummary: any;
  subscriptionPagingData: any;
  packagesObject: any;
  filter: any;
  loading: boolean;
  viewSubscription: ViewSubscriptionInterface | null;
  viewSubscriptionLoading: boolean;
  selectedMealDate: Date;
  deliveryShifts: DeliveryShift[];
  mealLoading: boolean;
  createPlannedOrderLoading: boolean;
  addPlannedItemLoading: boolean;
  addSubscriptionDaysLoading: boolean;
  removeSubscriptionDaysLoading: boolean;
  cancelSubscriptionLoading: boolean;
  refundRequestLoading: boolean;
  updateSubscriptionStartDateLoading: boolean;
  activePlannedDate: any;
  pauseDays: any;
  subscriptionDialogFullwidth: boolean;
  requestList: any;
  requestPaginationData: any;
  requestFilter: any;
  requestObj: any;
  orderDetail: any;
  portionList: PortionItem[];
}

const initialState: InitialState = {
  subscriptions_list: null,
  express_Order: null,
  subscriptionPagingData: null,
  packagesObject: {},
  filter: null,
  loading: false,
  viewExpressOrderSummary: null,
  viewSubscription: null,
  viewSubscriptionLoading: false,
  selectedMealDate: null,
  deliveryShifts: null,
  mealLoading: false,
  createPlannedOrderLoading: false,
  addPlannedItemLoading: false,
  addSubscriptionDaysLoading: false,
  removeSubscriptionDaysLoading: false,
  cancelSubscriptionLoading: false,
  refundRequestLoading: false,
  updateSubscriptionStartDateLoading: false,
  activePlannedDate: null,
  pauseDays: null,
  subscriptionDialogFullwidth: false,
  requestList: null,
  requestPaginationData: null,
  requestFilter: null,
  requestObj: null,
  orderDetail: null,
  portionList: [],
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS_LIST:
      draft.subscriptions_list = action.payload;
      draft.packagesObject = action.packagesObject;
      break;
    case SET_VIEW_ORDER_SUMMARY:
      draft.viewExpressOrderSummary = action.payload;
      break;
    case SET_SUBSCRIPTIONS_PAGE:
      draft.subscriptionPagingData = action.payload;
      break;
    case SET_REQUEST_LIST:
      draft.requestList = action.payload;
      break;
    case SET_REQUEST_PAGE:
      draft.requestPaginationData = action.payload;
      break;
    case SET_REQUEST_FILTER:
      draft.requestFilter = action.payload;
      break;
    case SET_SUBSCRIPTION_FILTER:
      draft.filter = action.payload;
      break;
    case SET_SUBSCRIPTIONS_LOADING:
      draft.loading = action.payload;
      break;
    case SET_VIEW_SUBSCRIPTION:
      draft.viewSubscription = action.payload;
      break;
    case SET_VIEW_SUBSCRIPTION_LOADING:
      draft.viewSubscriptionLoading = action.payload;
      break;
    case SET_DELIVERY_SHIFTS:
      draft.deliveryShifts = action.payload;
      break;
    case SET_MEAL_LOADER:
      draft.mealLoading = action.payload;
      break;
    case SET_CREATE_PLANNED_ORDER_LOADING:
      draft.createPlannedOrderLoading = action.payload;
      break;
    case SET_PLANNED_ITEM_LOADING:
      draft.addPlannedItemLoading = action.payload;
      break;
    case SET_ADD_SUBSCRIPTION_DAYS_LOADING:
      draft.addSubscriptionDaysLoading = action.payload;
      break;
    case SET_REMOVE_SUBSCRIPTION_DAYS_LOADING:
      draft.removeSubscriptionDaysLoading = action.payload;
      break;
    case CANCEL_SUBSCRIPTION_LOADING:
      draft.cancelSubscriptionLoading = action.payload;
      break;
    case REFUND_REQUEST_LOADING:
      draft.refundRequestLoading = action.payload;
      break;
    case SET_UPDATE_SUBSCRIPTION_START_DATE_LOADING:
      draft.updateSubscriptionStartDateLoading = action.payload;
      break;
    case SET_PLANNED_DATE:
      draft.activePlannedDate = action.payload;
      break;
    case SET_SUBS_DIALOG_FULLWIDTH:
      draft.subscriptionDialogFullwidth = action.payload;
      break;
    case SET_PAUSE_DAYS:
      draft.pauseDays = action.payload;
      break;
    case SET_REQUEST_OBJ:
      draft.requestObj = action.payload;
      break;
    case SET_ORDER_DETAIL:
      draft.orderDetail = action.payload;
      break;
    case SET_PORTION_LIST:
      draft.portionList = action.payload;
      break;
    case UPDATE_VIEW_SUBSCRIPTION_MEAL:
      draft.viewSubscription = {
        ...draft.viewSubscription,
        meals: action.payload,
      };
      break;

    case SET_SELECTED_MEAL_DATE_FOR_SUBSCRIPTION_VIEW:
      draft.selectedMealDate = action.payload;
      break;

    case UPDATE_ADDRESS_AND_SHIFT_IN_VIEW_SUBSCRIPTION:
      draft.viewSubscription = {
        ...draft.viewSubscription,
        plannedOrders: {
          ...draft.viewSubscription.plannedOrders,
          deliveryAddressId: action.payload.deliveryAddressId,
          deliveryShiftId: action.payload.deliveryShiftId,
        },
      };
      break;

    case UPDATE_START_END_DATE_IN_SUBSCRIPTIONS:
      draft.subscriptions_list = draft.subscriptions_list.map(
        (subscription) => {
          if (subscription.subscriptionId === action.payload.subscriptionId) {
            return {
              ...subscription,
              startAt: action.payload.startAt,
              endAt: action.payload.estimatedEndAt,
            };
          }
          return subscription;
        }
      );
      break;
    case UPDATE_STATUS_SUBSCRIPTIONS:
      draft.subscriptions_list = draft.subscriptions_list.map(
        (subscription) => {
          if (subscription.subscriptionId === action.payload.subscriptionId) {
            return {
              ...subscription,
              status: action.payload.status,
            };
          }
          return subscription;
        }
      );
      break;
    case CLEAR_SUBSCRIPTIONS_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
