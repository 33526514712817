import {
  Governate,
  Zones,
  DeliveryAreaList,
  productList,
  province,
  StocksFilter,
} from "app/types";
import {
  SET_CONFIGURATION_FILTER,
  SET_CONFIG_LOADING,
  SET_CONFIG_PAGE,
  SET_DELIVERY_AREA_LIST,
  SET_GOVERNATE,
  SET_PROVINCE,
  SET_ZONES,
  SET_ZONES_LIST,
  SET_PRODUCTS,
  SET_STOCKS_FILTER,
  SET_FILTERERD_AREA_LIST,
  SET_FILTERERD_ZONE_LIST,
  SET_SEARCH_FILTERERD_AREA_LIST,
  SET_SEARCH_FILTERERD_ZONE_LIST,
  SET_FILTERERD_PRODUCT_LIST,
  SET_STOCKS_PAGING,
  SET_STOCKS_CURRENT_PAGE,
  SET_STOCKS_NEXT_PAGE,
  SET_STOCKS_PREV_PAGE,
} from "app/utils/constants/configuration";
import produce from "immer";
interface ConfigurationInitState {
  zonesList: Zones[];
  deliveryAreaList: DeliveryAreaList[];
  filter: any;
  stocksFilter:StocksFilter;
  loading: boolean;
  nextPage: number;
  prevPage: number;
  zones: Zones[];
  provinceList: province[];
  governateList: Governate[];
  productList: productList[];
  filtererAreaList: DeliveryAreaList[];
  filteredZoneList: Zones[];
  searchFiltererAreaList: DeliveryAreaList[];
  searchFilteredZoneList: Zones[];
  filtererdProductList: productList[];
  zoneMapping: any[];
  current_page: number | null;
  next_page: number | null;
  prev_page: number | null;
  stocksLoading: boolean;
  current_limit: number | null;
}
const initialState: ConfigurationInitState = {
  zonesList: null,
  deliveryAreaList: null,
  filter: null,
  loading: false,
  nextPage: null,
  prevPage: null,
  zones: null,
  provinceList: null,
  governateList: null,
  productList: null,
  stocksFilter:null,
  filtererAreaList: null,
  filteredZoneList: null,
  searchFiltererAreaList: null,
  searchFilteredZoneList: null,
  filtererdProductList: null,
  zoneMapping: null,
  current_page: null,
  current_limit: null,
  next_page: null,
  prev_page: null,
  stocksLoading: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_ZONES_LIST:
      return {
        ...draft,
        zonesList: action.payload,
        zoneMapping:
          action.payload?.length > 0
            ? Object.fromEntries(
                action.payload.map((item: any) => [item.id, item])
              )
            : {},
      };
    case SET_FILTERERD_AREA_LIST:
      return {
        ...draft,
        filtererAreaList: action.payload,
      };
    case SET_SEARCH_FILTERERD_AREA_LIST:
      return {
        ...draft,
        searchFiltererAreaList: action.payload,
      };
    case SET_SEARCH_FILTERERD_ZONE_LIST:
      return {
        ...draft,
        searchFilteredZoneList: action.payload,
      };
    case SET_FILTERERD_PRODUCT_LIST:
      return {
        ...draft,
        filtererdProductList: action.payload,
      };
    case SET_STOCKS_CURRENT_PAGE:
      draft.current_page = action.payload;
      break;
    case SET_STOCKS_NEXT_PAGE:
      draft.next_page = action.payload;
      break;
    case SET_STOCKS_PREV_PAGE:
      draft.prev_page = action.payload;
      break;
    case SET_STOCKS_PAGING:
      draft.current_limit = action.payload.current_limit;
      draft.current_page = action.payload.current_page;
      draft.next_page = action.payload.next_page;
      draft.prev_page = action.payload.prev_page;
      break;
    case SET_FILTERERD_ZONE_LIST:
      return {
        ...draft,
        filteredZoneList: action.payload,
      };
    case SET_ZONES:
      return {
        ...draft,
        zones: action.payload,
      };
    case SET_PROVINCE:
      return {
        ...draft,
        provinceList: action.payload,
      };
    case SET_PRODUCTS:
      return {
        ...draft,
        productList: action.payload,
      };
    case SET_GOVERNATE:
      return {
        ...draft,
        governateList: action.payload,
      };
    case SET_DELIVERY_AREA_LIST:
      return { ...draft, deliveryAreaList: action.payload };
    case SET_CONFIGURATION_FILTER:
      draft.filter = action.payload;
      break;
    case SET_STOCKS_FILTER:
      draft.stocksFilter = action.payload;
      break;
    case SET_CONFIG_PAGE:
      return {
        ...draft,
        nextPage: action.payload.nextPage,
        prevPage: action.payload.prevPage,
      };
    case SET_CONFIG_LOADING:
      draft.loading = action.payload;
      break;
    default:
      return draft;
  }
}, initialState);
