import produce from "immer";
import {
  SET_CLIENTS_LIST,
  SET_CLIENTS_WAITING_LIST,
  SET_CLIENTS_CURRENT_PAGE,
  SET_CLIENTS_NEXT_PAGE,
  SET_CLIENTS_PREV_PAGE,
  SET_CLIENTS_PAGE,
  SET_CLIENTS_FILTER,
  SET_CLIENTS_LOADING,
  CLEAR_CLIENTS_STORE,
  SET_ALLERGIES,
  SET_DISLIKES,
  SET_LIKES,
  SET_VISIT_DATA,
  SET_VISIT_DATA_LOADER,
  SET_VISIT_FILTER,
  SET_CLIENT_VISIT_DETAIL,
  SET_CLIENT_DETAILS,
  SET_DIETICIAN,
  SET_SCHED_VISIT_DATA,
  SET_SCHED_VISIT_LOADER,
  SET_SCHED_VISIT_FILTER,
  SET_SCHED_VISIT_DETAIL,
  SET_STORE_ANALYTICS,
  SET_CLIENT_DETAILS_LOADING,
} from "app/utils/constants/clients";
import {
  MealPreferenceItemType,
  StoreAnalyticsInterface,
  clientsWaitingListType,
} from "app/types";

interface InitialStateInterface {
  clients_list: any[];
  clientsWaitingList: clientsWaitingListType[];
  current_page: number | null;
  next_page: number | null;
  prev_page: number | null;
  current_limit: number | null;
  filter: any;
  loading: boolean;
  allergies: any[];
  storeAnalytics: StoreAnalyticsInterface;
  dislikes: MealPreferenceItemType[];
  likes: any[];
  clientVisits: any;
  clientVisitsNextToken: any;
  clientVisitsPrevToken: any;
  clientVisitsLoader: boolean;
  clientVisitFilter: any;
  clientVisit: any;
  clientDetails: any;
  clientDetailsLoading: boolean;
  dieticians: any;

  scheduleVisits: any;
  scheduleVisitsNextToken: any;
  scheduleVisitsPrevToken: any;
  scheduleVisitsLoading: boolean;
  scheduleVisitsFilter: any;
  scheduleVisitDetail: any;
}

const initialState: InitialStateInterface = {
  clients_list: [],
  clientsWaitingList: [],
  storeAnalytics: null,
  current_page: null,
  next_page: null,
  prev_page: null,
  current_limit: null,
  filter: null,
  loading: false,
  allergies: [],
  dislikes: [],
  likes: [],
  clientVisits: null,
  clientVisitsNextToken: null,
  clientVisitsPrevToken: null,
  clientVisitsLoader: false,
  clientVisitFilter: null,
  clientVisit: null,
  clientDetails: null,
  clientDetailsLoading: false,
  dieticians: null,

  scheduleVisits: null,
  scheduleVisitsNextToken: null,
  scheduleVisitsPrevToken: null,
  scheduleVisitsLoading: false,
  scheduleVisitsFilter: null,
  scheduleVisitDetail: null,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CLIENTS_LIST:
      draft.clients_list = action.payload;
      break;
    case SET_CLIENTS_WAITING_LIST:
      draft.clientsWaitingList = action.payload;
      break;
    case SET_CLIENTS_CURRENT_PAGE:
      draft.current_page = action.payload;
      break;
    case SET_CLIENTS_NEXT_PAGE:
      draft.next_page = action.payload;
      break;
    case SET_CLIENTS_PREV_PAGE:
      draft.prev_page = action.payload;
      break;
    case SET_CLIENTS_PAGE:
      draft.current_limit = action.payload.current_limit;
      draft.current_page = action.payload.current_page;
      draft.next_page = action.payload.next_page;
      draft.prev_page = action.payload.prev_page;
      break;
    case SET_CLIENTS_FILTER:
      draft.filter = action.payload;
      break;
    case SET_CLIENTS_LOADING:
      draft.loading = action.payload;
      break;
    case SET_ALLERGIES:
      draft.allergies = action.payload;
      break;
    case SET_STORE_ANALYTICS:
      draft.storeAnalytics = action.payload;
      break;
    case SET_DISLIKES:
      draft.dislikes = action.payload;
      break;
    case SET_LIKES:
      draft.likes = action.payload;
      break;
    case SET_VISIT_DATA:
      draft.clientVisits = action.payload.data;
      draft.clientVisitsNextToken = action.payload.nextToken;
      draft.clientVisitsPrevToken = action.payload.prevToken;
      break;
    case SET_VISIT_DATA_LOADER:
      draft.clientVisitsLoader = action.payload;
      break;
    case SET_VISIT_FILTER:
      draft.clientVisitFilter = action.payload;
      break;
    case SET_CLIENT_VISIT_DETAIL:
      draft.clientVisit = action.payload;
      break;
    case SET_CLIENT_DETAILS:
      draft.clientDetails = action.payload;
      break;

    case SET_CLIENT_DETAILS_LOADING:
      draft.clientDetailsLoading = action.payload;
      break;
    case SET_DIETICIAN:
      draft.dieticians = action.payload;
      break;

    case SET_SCHED_VISIT_DATA:
      draft.scheduleVisits = action.payload.data;
      draft.scheduleVisitsNextToken = action.payload.nextToken;
      draft.scheduleVisitsPrevToken = action.payload.prevToken;
      break;
    case SET_SCHED_VISIT_LOADER:
      draft.scheduleVisitsLoading = action.payload;
      break;
    case SET_SCHED_VISIT_FILTER:
      draft.scheduleVisitsFilter = action.payload;
      break;
    case SET_SCHED_VISIT_DETAIL:
      draft.scheduleVisitDetail = action.payload;
      break;

    case CLEAR_CLIENTS_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
