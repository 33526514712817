import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import {
  getClientIdentityApi,
  updateClientIdentityStatusApi,
} from "app/services/clients";
import { ClientIdentity } from "app/types/clients";
import { alertMessage } from "../actions/common";

interface InitialState {
  identity: ClientIdentity;
}

const initialState: InitialState = {
  identity: null,
};

export const clientsSlice = createSlice({
  name: "clientSlice",
  initialState,
  reducers: {
    setClientIdentity: (
      state,
      action: PayloadAction<ClientIdentity | null>
    ) => {
      state.identity = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClientIdentity } = clientsSlice.actions;

export default clientsSlice.reducer;

export const getClientIdentityAction =
  (clientId: number) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await getClientIdentityApi(clientId);
      const identity = data.data;
      const users = data.included.users;

      // find user by id in logs from included users
      const logs = identity.logs.map((log) => {
        const user = users.find((user) => user.userId === log.userId);
        return { ...log, user };
      });

      const clientIdentity = { ...identity, logs };
      dispatch(setClientIdentity(clientIdentity));
    } catch (error) {
      // Client has no identity
      dispatch(setClientIdentity(null));
    }
  };

type UpdateClientIdentityStatusRequestPayload = {
  identityId: number;
  type: string;
  enable: boolean;
  reason: string;
  clientId: number;
  successCallback: () => void;
};
export const updateClientIdentityStatusAction =
  ({
    identityId,
    type,
    enable,
    reason,
    clientId,
    successCallback,
  }: UpdateClientIdentityStatusRequestPayload) =>
  async (dispatch: AppDispatch) => {
    // Update client identity status
    try {
      const { data } = await updateClientIdentityStatusApi({
        identityId,
        type,
        enable,
        reason,
      });

      if (data.data.message) {
        // We refresh the client identity data
        dispatch(getClientIdentityAction(clientId));
        successCallback();
      }
    } catch (error) {
      dispatch(alertMessage("error", "Error updating client identity status"));
    }
  };
