import React from "react";
import Page from "@jumbo/shared/Page";

const Subscription = React.lazy(() => import("../pages/subscription"));
const SubscriptionRequest = React.lazy(() =>
  import("../pages/subscription/Request")
);
const PageNotFound = React.lazy(() => import("../pages/pageNotFound"));
const Home = React.lazy(() => import("../pages/home"));
const Clients = React.lazy(() => import("../pages/clients"));

const SubscriptionView = React.lazy(() =>
  import("../pages/subscription/subscriptionView")
);
const orders = React.lazy(() => import("../pages/expressOrder"));
const Production = React.lazy(() => import("../pages/production"));
const Catalog = React.lazy(() => import("../pages/menus/catalog"));
const DeliveryOrder = React.lazy(() => import("../pages/deliveryOrders"));
const ClientOrder = React.lazy(() => import("../pages/clientOrder"));
const Meal = React.lazy(() => import("../pages/menus/meal"));
const Visit = React.lazy(() =>
  import("../pages/clients/visits/VisitComponents/VisitMainComponent")
);
const ScheduleVisit = React.lazy(() =>
  import("../pages/clients/visits/VisitComponents/ScheduleVisit")
);
const Products = React.lazy(() => import("../pages/products/Products"));
const Stocks = React.lazy(() => import("../pages/stocks/StocksList"));
const Consultation = React.lazy(() => import("../pages/consultations"));
const DeliveryArea = React.lazy(() => import("../pages/Configurations/DeliveryArea"));
const customerSupport = React.lazy(() => import("../pages/LiveChat"));
// analytics
const SaudiAnalytics = React.lazy(() => import("../pages/analytic/SaudiAnalytics"));
const AnalyticsDashboard = React.lazy(() => import("../pages/analytic/AnalyticsHome"));
const BestSellingDetails = React.lazy(() => import("../pages/analytic/BestSellingDetails"));
const PeakAndDipDetails = React.lazy(() => import("../pages/analytic/PeakAndDipDetails"));
const RetentionDetails = React.lazy(() => import("../pages/analytic/RententionDetails"));
const BranchSalesDetails = React.lazy(() => import("../pages/analytic/BranchSalesDetails"));

// Distribution
const DistributionTasks = React.lazy(() => import("../pages/distribution/Distribution"));
const ManageDrivers = React.lazy(() => import("../pages/distribution/ManageDrivers"));
const DistributionDeliveries = React.lazy(() => import("../pages/distribution/Deliveries"))
const DeliveryPlanning = React.lazy(() => import("../pages/distribution/DeliveryPlanning"));

export const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Page component={Home} />,
  },

  // Distribution routes
  {
    path: "/dashboards/distribution-tasks",
    element: <Page component={DistributionTasks} />,
  },
  {
    path: "/dashboards/manage-drivers",
    element: <Page component={ManageDrivers} />,
  },
  {
    path: "/dashboards/distribution-deliveries",
    element: <Page component={DistributionDeliveries} />,
  },
  {
    path: "/dashboards/distribution-delivery-planning",
    element: <Page component={DeliveryPlanning} />,
  },

  // Subscriptions routes
  {
    path: "/dashboards/subscriptions",
    element: <Page component={Subscription} />,
  },
  {
    path: "/dashboards/subscriptions/request",
    element: <Page component={SubscriptionRequest} />,
  },

  {
    path: "/dashboards/subscriptions/view/:id",
    element: <Page component={SubscriptionView} />,
  },

  // Clients routes
  {
    path: "/dashboards/clients",
    element: <Page component={Clients} />,
  },

  //   Production routes
  {
    path: "/dashboards/productions",
    element: <Page component={Production} />,
  },
  // Delivery Order routes
  {
    path: "dashboards/delivery-orders",
    element: <Page component={DeliveryOrder} />,
  },
  // orders routes
  {
    path: "/dashboards/orders",
    element: <Page component={orders} />,
  },
  {
    path: "/dashboards/saudi-analytics",
    element: <Page component={SaudiAnalytics} />,
  },
  {
    path: "/dashboards/analytics",
    element: <Page component={AnalyticsDashboard} />,
  },
  {
    path: "/dashboards/bestselling-details",
    element: <Page component={BestSellingDetails} />,
  },
  {
    path: "/dashboards/retention-details",
    element: <Page component={RetentionDetails} />,
  },
  {
    path: "/dashboards/peakdip-details",
    element: <Page component={PeakAndDipDetails} />,
  },
  {
    path: "/dashboards/branch-sales",
    element: <Page component={BranchSalesDetails} />,
  },
  //   Menu routes
  {
    path: "/dashboards/menus/catalogs",
    element: <Page component={Catalog} />,
  },
  {
    path: "/dashboards/menus/meals",
    element: <Page component={Meal} />,
  },

  {
    path: "/dashboards/client/orders",
    element: <Page component={ClientOrder} />,
  },

  {
    path: "/dashboards/visits",
    element: <Page component={Visit} />,
  },
  {
    path: "/dashboards/schedule/visits",
    element: <Page component={ScheduleVisit} />,
  },
  {
    path: "/dashboards/consultations",
    element: <Page component={Consultation} />,
  },
  {
    path: "/dashboards/customerSupport",
    element: <Page component={customerSupport} />,
  },
  //products roots
  {
    path: "/dashboards/products",
    element: <Page component={Products} />,
  },
  {
    path: "/dashboards/stocks",
    element: <Page component={Stocks} />,
  },
  {
    path: "/dashboards/deliveryAreas",
    element: <Page component={DeliveryArea} />,
  },
  {
    path: "/error/500",
    element: <Page component={PageNotFound} error={500} />,
  },

  {
    path: "*",
    element: <Page component={PageNotFound} error={400} />,
  },
];
